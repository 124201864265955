import { notificationController } from '@app/controllers/notificationController';

import {
  CompanyModel,
  GetCompanies,
  CreateCompany,
  GetSingleCompanyData,
  UpdateCompany,
  DeleteCompany,
  GetAllCompanies,
  TestIntegration,
} from './types';
import { sleep } from '@app/utils';

const mockedCompany: CompanyModel = {
  companyName: 'Bodeguinha',
  fantasyName: 'Bodeguinha',
  cnpj: '49.000.000/0001-00',
  email: 'bodeguinha@email.com',
  phone: '+5511999999999',
  mainActivity: 'serviços',
  address: {
    city: 'Aracaju',
    state: 'Sergipe',
    streetNumber: '10',
    postalcode: '49000-000',
    street: 'Rua dez',
  },
  bankAccounts: [],
};

export const getCompanies: GetCompanies = async () => {
  await sleep(2000);
  return [{ uid: '1', ...mockedCompany }];
};

export const createCompany: CreateCompany = async () => {
  await sleep(2000);
  notificationController.success({ message: 'Empresa criada com sucesso.' });
  return undefined;
};

export const getSingleCompanyData: GetSingleCompanyData = async () => {
  await sleep(2000);
  return mockedCompany;
};

export const updateCompany: UpdateCompany = async () => {
  await sleep(2000);
};

export const deleteCompany: DeleteCompany = async () => {
  await sleep(2000);
};

export const getAllCompanies: GetAllCompanies = async () => {
  await sleep(2000);

  return [
    { uid: '1', ...mockedCompany },
    { uid: '2', ...mockedCompany },
  ];
};

export const testIntegration: TestIntegration = async () => {
  await sleep(2000);

  notificationController.success({ message: 'Integração validada com sucesso' });
  return undefined;
};
