import { createAction, createSlice, PrepareAction, Slice } from '@reduxjs/toolkit';
import { UserModel } from '@app/services/firebase/users';
import { doLogin } from './authSlice';
import { persistUser, readUser } from '@app/services/localStorage';

export type UserState = UserModel | null;

const initialState: UserState = readUser();

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const userSlice: Slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state = action.payload;
    });
    builder.addCase(doLogin.fulfilled, (state, action) => (state = action.payload.user));
  },
});

export default userSlice.reducer;
