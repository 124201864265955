import { Roles } from '@app/services/permissions/enums';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { validateUserRoles } from '@app/services/permissions';

export const useIsAdmin = () => {
  const auth = useAppSelector((state) => state.auth);
  const userRoles = auth?.roles;

  const { hasSome } = validateUserRoles({ userRoles, roles: [Roles.Admin] });

  return hasSome;
};
