import React from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ControlWrap from '@app/config/router/ControlWrap';

// no lazy loading for auth pages to avoid flickering
import LoginPage from '@app/pages/Auth/LoginPage';
import ForgotPasswordPage from '@app/pages/Auth/ForgotPasswordPage';
import ProfileLayout from '@app/pages/Profile';

const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
const Logout = React.lazy(() => import('./Logout'));
const Home = React.lazy(() => import('@app/pages/Home/Home'));
const ServerError = React.lazy(() => import('@app/pages/Errors/ServerErrorPage'));
const Error404 = React.lazy(() => import('@app/pages/Errors/Error404Page'));
const PersonalInfo = React.lazy(() => import('@app/pages/Profile/containers/PersonalInfo/PersonalInfo'));
const SecuritySettings = React.lazy(
  () => import('@app/pages/Profile/containers/SecuritySettings/SecuritySettingsPage'),
);
const UsersTable = React.lazy(() => import('@app/pages/Users'));
const UserLayout = React.lazy(() => import('@app/pages/Users/UserData'));
const UserForm = React.lazy(() => import('@app/pages/Users/NewUserForm/'));
const RolesTable = React.lazy(() => import('@app/pages/Roles'));
const RoleForm = React.lazy(() => import('@app/pages/Roles/NewRoleForm'));
const RoleInfo = React.lazy(() => import('@app/pages/Roles/RoleInfo'));
const CompaniesTable = React.lazy(() => import('@app/pages/Companies'));
const CompanyForm = React.lazy(() => import('@app/pages/Companies/NewCompanyForm'));
const CompanyInfo = React.lazy(() => import('@app/pages/Companies/CompanyInfo'));
const FinancesTable = React.lazy(() => import('@app/pages/Finances'));
const FinancesPeriodInfo = React.lazy(() => import('@app/pages/Finances/TransactionsInfo'));
const SelectMonthDemonstrative = React.lazy(
  () => import('@app/pages/Finances/MonthlyDemonstrativeResults/SelectMonth'),
);
const MonthlyDemonstrative = React.lazy(() => import('@app/pages/Finances/MonthlyDemonstrativeResults'));
const SelectYearConsolidated = React.lazy(() => import('@app/pages/Finances/ConsolidatedDemonstrative/SelectYear'));
const ConsolidatedDemonstrative = React.lazy(() => import('@app/pages/Finances/ConsolidatedDemonstrative'));
const NewAccountsPlan = React.lazy(() => import('@app/pages/Finances/AccountPlans/NewAccount'));
const AccountsPlansTable = React.lazy(() => import('@app/pages/Finances/AccountPlans'));
const AccountsPlanInfo = React.lazy(() => import('@app/pages/Finances/AccountPlans/AccountsPlanInfo'));
const MonthReport = React.lazy(() => import('@app/pages/Finances/Report'));
const ConsolidatedReport = React.lazy(() => import('@app/pages/Finances/ConsolidatedDemonstrative/ConsolidatedReport'));

import { withLoading } from '@app/hocs/withLoading.hoc';
import routeParams from './utils/routeParams';

const HomePage = withLoading(Home);
// Error Pages
const ServerErrorPage = withLoading(ServerError);
const Error404Page = withLoading(Error404);
// Profile Pages
const ProfilePage = withLoading(PersonalInfo);
const SecuritySettingsPage = withLoading(SecuritySettings);
// Auth Pages
const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
// Users Pages
const UsersTablePage = withLoading(UsersTable);
const UserInfoPage = withLoading(UserLayout);
const UserFormPage = withLoading(UserForm);
// Access Roles Pages
const RolesTablePage = withLoading(RolesTable);
const RoleFormPage = withLoading(RoleForm);
const RoleInfoPage = withLoading(RoleInfo);
// Companies Pages
const CompaniesTablePage = withLoading(CompaniesTable);
const CompanyFormPage = withLoading(CompanyForm);
const CompanyInfoPage = withLoading(CompanyInfo);
// Finances Pages
const FinancesTablePage = withLoading(FinancesTable);
const FinancesPeriodInfoPage = withLoading(FinancesPeriodInfo);
const SelectMonthPage = withLoading(SelectMonthDemonstrative);
const MonthlyDemonstrativePage = withLoading(MonthlyDemonstrative);
const SelectYearConsolidatedPage = withLoading(SelectYearConsolidated);
const ConsolidatedDemonstrativePage = withLoading(ConsolidatedDemonstrative);
const MonthReportPage = withLoading(MonthReport);
const ConsolidatedReportPage = withLoading(ConsolidatedReport);
// Account Plans
const NewAccountsPlanPage = withLoading(NewAccountsPlan);
const AccountsPlansTablePage = withLoading(AccountsPlansTable);
const AccountsPlanInfoPage = withLoading(AccountsPlanInfo);

export const routes = {
  HOME_SCREEN: {
    path: routeParams.home.path,
    permissions: routeParams.home.permissions,
    element: (
      <ControlWrap allRequiredPermissions={[]} permissions={routeParams.home.permissions}>
        <MainLayout>
          <HomePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  ERROR_404_SCREEN: {
    path: routeParams.error404.path,
    permissions: routeParams.error404.permissions,
    element: (
      <ControlWrap allRequiredPermissions={[]} permissions={routeParams.error404.permissions}>
        <MainLayout>
          <Error404Page />
        </MainLayout>
      </ControlWrap>
    ),
  },
  SERVER_ERROR_SCREEN: {
    path: routeParams.serverError.path,
    permissions: routeParams.serverError.permissions,
    element: (
      <ControlWrap allRequiredPermissions={[]} permissions={routeParams.serverError.permissions}>
        <MainLayout>
          <ServerErrorPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  MAIN_PROFILE_SCREEN: {
    path: routeParams.profile.path,
    permissions: routeParams.profile.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.profile.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <ProfileLayout />
        </MainLayout>
      </ControlWrap>
    ),
  },
  PROFILE_INFO_SCREEN: {
    path: routeParams.personalInfo.path,
    permissions: routeParams.personalInfo.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.personalInfo.permissions}
        redirect={<Navigate to={routeParams.securitySettings.path} replace />}
      >
        <MainLayout>
          <ProfileLayout>
            <ProfilePage />
          </ProfileLayout>
        </MainLayout>
      </ControlWrap>
    ),
  },
  PROFILE_SECURITY_SCREEN: {
    path: routeParams.securitySettings.path,
    permissions: routeParams.securitySettings.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.securitySettings.permissions}
        redirect={<Navigate to={routeParams.home.path} replace />}
      >
        <MainLayout>
          <ProfileLayout>
            <SecuritySettingsPage />
          </ProfileLayout>
        </MainLayout>
      </ControlWrap>
    ),
  },
  USERS_TABLE_SCREEN: {
    path: routeParams.users.path,
    permissions: routeParams.users.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.users.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <UsersTablePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  USER_FORM_SCREEN: {
    path: routeParams.newUser.path,
    permissions: routeParams.newUser.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.newUser.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <UserFormPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  USER_INFO_SCREEN: {
    path: routeParams.userInfo.path,
    permissions: routeParams.userInfo.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.userInfo.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <UserInfoPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  ROLES_TABLE_SCREEN: {
    path: routeParams.roles.path,
    permissions: routeParams.roles.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.roles.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <RolesTablePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  ROLES_FORM_SCREEN: {
    path: routeParams.newRole.path,
    permissions: routeParams.newRole.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.newRole.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <RoleFormPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  ROLE_INFO_SCREEN: {
    path: routeParams.roleInfo.path,
    permissions: routeParams.roleInfo.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.roleInfo.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <RoleInfoPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  COMPANIES_TABLE_SCREEN: {
    path: routeParams.companies.path,
    permissions: routeParams.companies.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.companies.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <CompaniesTablePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  COMPANY_FORM_SCREEN: {
    path: routeParams.newCompany.path,
    permissions: routeParams.newCompany.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.newCompany.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <CompanyFormPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  COMPANY_INFO_SCREEN: {
    path: routeParams.companyInfo.path,
    permissions: routeParams.companyInfo.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.companyInfo.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <CompanyInfoPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  FINANCES_TABLE_SCREEN: {
    path: routeParams.financesTable.path,
    permissions: routeParams.financesTable.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.financesTable.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <FinancesTablePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  FINANCES_PERIOD_INFO_SCREEN: {
    path: routeParams.financesPeriodInfo.path,
    permissions: routeParams.financesPeriodInfo.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.financesTable.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <FinancesPeriodInfoPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  SELECT_MONTH_SCREEN: {
    path: routeParams.selectDemonstrativeMonth.path,
    permissions: routeParams.selectDemonstrativeMonth.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.selectDemonstrativeMonth.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <SelectMonthPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  MONTH_DEMONSTRATIVE_SCREEN: {
    path: routeParams.demonstrativeResults.path,
    permissions: routeParams.demonstrativeResults.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.demonstrativeResults.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <MonthlyDemonstrativePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  SELECT_YEAR_SCREEN: {
    path: routeParams.selectYearConsolidated.path,
    permissions: routeParams.selectYearConsolidated.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.selectYearConsolidated.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <SelectYearConsolidatedPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  CONSOLIDATED_DEMONSTRATIVE_SCREEN: {
    path: routeParams.consolidatedDemonstrative.path,
    permissions: routeParams.consolidatedDemonstrative.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.consolidatedDemonstrative.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <ConsolidatedDemonstrativePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  NEW_ACCOUNTS_PLAN_SCREEN: {
    path: routeParams.newAccountsPlan.path,
    permissions: routeParams.newAccountsPlan.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.newAccountsPlan.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <NewAccountsPlanPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  ACCOUNTS_PLANS_SCREEN: {
    path: routeParams.accountsPlansTable.path,
    permissions: routeParams.accountsPlansTable.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.accountsPlansTable.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <AccountsPlansTablePage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  ACCOUNTS_PLAN_INFO_SCREEN: {
    path: routeParams.accountsPlanInfo.path,
    permissions: routeParams.accountsPlanInfo.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.accountsPlanInfo.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <AccountsPlanInfoPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  MONTH_REPORT_SCREEN: {
    path: routeParams.monthReport.path,
    permissions: routeParams.monthReport.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.monthReport.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <MonthReportPage />
        </MainLayout>
      </ControlWrap>
    ),
  },
  CONSOLIDATED_REPORT_SCREEN: {
    path: routeParams.consolidatedReport.path,
    permissions: routeParams.consolidatedReport.permissions,
    element: (
      <ControlWrap
        allRequiredPermissions={[]}
        permissions={routeParams.consolidatedReport.permissions}
        redirect={<Navigate to={routeParams.error404.path} replace />}
      >
        <MainLayout>
          <ConsolidatedReportPage />
        </MainLayout>
      </ControlWrap>
    ),
  },

  LOGIN_SCREEN: {
    path: routeParams.login.path,
    permissions: routeParams.login.permissions,
    element: (
      <AuthLayoutFallback>
        <LoginPage />
      </AuthLayoutFallback>
    ),
  },
  FORGOT_PASSWORD_SCREEN: {
    path: routeParams.forgotPassword.path,
    permissions: routeParams.forgotPassword.permissions,
    element: (
      <AuthLayoutFallback>
        <ForgotPasswordPage />
      </AuthLayoutFallback>
    ),
  },
  LOGOUT_SCREEN: {
    path: routeParams.logout.path,
    permissions: [],
    element: <LogoutFallback />,
  },
};
