type Env = {
  REACT_APP_BASE_URL: string;
  REACT_APP_ASSETS_BUCKET: string;
  REACT_APP_USE_MOCK: boolean;
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: number;
  REACT_APP_FIREBASE_MEASUREMENT_ID: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  ESLINT_NO_DEV_ERRORS: boolean;
  TSC_COMPILE_ON_ERROR: boolean;
};

const env: Env = Object.freeze({
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL || '',
  REACT_APP_ASSETS_BUCKET: process.env.REACT_APP_ASSETS_BUCKET || '',
  REACT_APP_USE_MOCK: JSON.parse(process.env.REACT_APP_USE_MOCK || 'false'),
  REACT_APP_FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY || '',
  REACT_APP_FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  REACT_APP_FIREBASE_APP_ID: Number(process.env.REACT_APP_FIREBASE_APP_ID),
  REACT_APP_FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
  REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  REACT_APP_FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  ESLINT_NO_DEV_ERRORS: JSON.parse(process.env.ESLINT_NO_DEV_ERRORS || 'false'),
  TSC_COMPILE_ON_ERROR: JSON.parse(process.env.TSC_COMPILE_ON_ERROR || 'false'),
});

export default env;
