import firebase from 'firebase/compat';
import env from '@app/config/env';

import { notificationController } from '@app/controllers/notificationController';
import { Permissions } from './enums';

import {
  ValidateUserRoles,
  ValidateUserPermissions,
  GetAccessRoles,
  AccessRoleTableData,
  PermissionData,
  GetPermissionsData,
  CreateAccessRole,
  GetSingleRoleData,
  AccessRole,
  UpdateAccessRole,
  DeleteAccessRole,
  GetAllAccessRoles,
  AccessRoleAllData,
} from './types';

const isMocked = env.REACT_APP_USE_MOCK;

export const getAccessRoles: GetAccessRoles = async (params) => {
  const { accessRoleData, queryCommand, pageSize } = params;
  let query = firebase
    .firestore()
    .collection('roles')
    .orderBy('roleName')
    .limit(pageSize + 1);

  if (accessRoleData && queryCommand) {
    if (queryCommand === 'next') {
      query = query.startAt(accessRoleData.snapshotRef).limit(pageSize + 1);
    } else if (queryCommand === 'previous') {
      query = query.endAt(accessRoleData.snapshotRef).limitToLast(pageSize + 1);
    }
  }

  try {
    const accessRolesTableData: AccessRoleTableData[] = [];
    const querySnap = await query.get();

    const queryPromises = querySnap.docs.map(async (doc) => {
      const rolePermissions: PermissionData[] = [];
      const data = doc.data();

      const permissionsPromise = data.permissions.map(async (permissionRef: firebase.firestore.DocumentReference) => {
        const permissionData = (await permissionRef.get()).data();

        if (!permissionData) {
          return;
        }

        rolePermissions.push(permissionData as PermissionData);
      });

      await Promise.all(permissionsPromise);

      accessRolesTableData.push({
        ...data,
        snapshotRef: doc,
        permissions: rolePermissions,
        uid: doc.id,
      } as AccessRoleTableData);
    });

    await Promise.all(queryPromises);

    return accessRolesTableData;
  } catch (err) {
    isMocked && console.error(`[ERROR - getUsersData]: `, err);
    return [];
  }
};

export const getAllAccessRoles: GetAllAccessRoles = async () => {
  try {
    const accessRolesTableData: AccessRoleAllData[] = [];
    const querySnap = await firebase.firestore().collection('roles').orderBy('roleName').get();

    querySnap.docs.forEach(async (doc) => {
      const data = doc.data();

      accessRolesTableData.push({
        ...data,
        uid: doc.id,
      } as AccessRoleAllData);
    });

    return accessRolesTableData;
  } catch (err) {
    if (isMocked) {
      console.error(`[ERROR - getAllAccessRoles]: `, err);
    }
    return [];
  }
};

export const getPermissionsData: GetPermissionsData = async () => {
  try {
    const permissionsSnap = await firebase
      .firestore()
      .collection('permissions')
      .orderBy('feature')
      .orderBy('name')
      .get();

    if (permissionsSnap.empty) {
      throw new Error('No data found');
    }

    const permissionsData = permissionsSnap.docs.map((doc) => {
      return { ...doc.data(), ref: doc.ref.path };
    });

    return permissionsData as PermissionData[];
  } catch (error) {
    isMocked && console.error('[ERROR - getPermissionsData]: ', error);
    notificationController.error({ message: 'Erro ao carregar permissões do sistema.' });
    return [];
  }
};

export const getSingleRoleData: GetSingleRoleData = async (uid) => {
  try {
    const roleSnap = await firebase.firestore().collection('roles').doc(uid).get();

    if (!roleSnap.exists) {
      throw new Error('Perfil de acesso não encontrado na base de dados.');
    }

    const rolePermissions: PermissionData[] = [];

    const { permissions, ...roleData } = roleSnap.data() as any;

    const permissionsPromises = permissions.map(async (permissionRef: firebase.firestore.DocumentReference) => {
      const permissionData = (await permissionRef.get()).data();

      if (!permissionData) {
        return;
      }

      rolePermissions.push({ ...permissionData, ref: permissionRef.path } as PermissionData);
    });

    await Promise.all(permissionsPromises);

    return { ...roleData, uid: roleSnap.id, permissions: rolePermissions } as AccessRole;
  } catch (error) {
    isMocked && console.error('[ERROR - getSingleRoleData]: ', error);
    notificationController.error({ message: 'Erro ao carregar dados do perfil selecionado.' });
  }
};

export const createAccessRole: CreateAccessRole = async (params) => {
  const { roleName, roleDescription, permissions } = params;

  const key = roleName.slice(0, 3) + String(Date.now());

  const permissionsRefs = permissions.map((permissionPath) => {
    return firebase.firestore().doc(permissionPath);
  });

  try {
    await firebase
      .firestore()
      .collection('roles')
      .add({ key, roleName, description: roleDescription, permissions: permissionsRefs });
    notificationController.success({ message: 'Perfil de acesso criado com sucesso.' });
  } catch (error) {
    isMocked && console.error('[ERROR - createAccessRole]: ', error);
    notificationController.error({ message: 'Erro ao criar novo perfil de acesso.' });
  }
};

export const updateAccessRole: UpdateAccessRole = async (params) => {
  const { uid, data } = params;

  const permissionsRefs = data.permissions.map((permissionPath) => {
    return firebase.firestore().doc(permissionPath);
  });

  try {
    await firebase
      .firestore()
      .collection('roles')
      .doc(uid)
      .update({ ...data, permissions: permissionsRefs });
    notificationController.success({ message: 'Perfil de acesso atualizado com sucesso.' });
  } catch (err) {
    notificationController.error({ message: 'Erro ao atualizar usuário.' });

    isMocked && console.error(err);
    throw err;
  }
};

export const deleteAccessRole: DeleteAccessRole = async (params) => {
  const { uid, permissions, isAdmin } = params;

  const canDeleteRole = isAdmin || permissions.includes(Permissions['EDIT_ROLES']);

  if (!canDeleteRole) {
    notificationController.error({ message: 'Usuário não tem permissão para deletar perfis de acesso' });
    return;
  }

  try {
    await firebase.firestore().collection('roles').doc(uid).delete();
  } catch (error) {
    notificationController.error({ message: 'Erro ao tentar deletar perfil de acesso' });
    isMocked && console.error('[ERROR - deleteAccessRole]: ', error);
  }
};

export const validateUserRoles: ValidateUserRoles = (params) => {
  const { userRoles, roles } = params;

  if (!roles.length) {
    return { hasEvery: true, hasSome: true };
  }

  if (!userRoles) {
    return { hasEvery: false, hasSome: false };
  }

  const hasEvery = roles.every((role) => userRoles.includes(role));
  const hasSome = roles.some((role) => userRoles.includes(role));

  return { hasEvery, hasSome };
};

export const validateUserPermissions: ValidateUserPermissions = (params) => {
  const { userPermissions, permissions } = params;

  if (!permissions.length) {
    return { hasEvery: true, hasSome: true };
  }

  if (!userPermissions) {
    return { hasEvery: false, hasSome: false };
  }

  const hasEvery = permissions.every((permissions) => userPermissions.includes(permissions));
  const hasSome = permissions.some((permissions) => userPermissions.includes(permissions));

  return { hasEvery, hasSome };
};
