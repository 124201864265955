import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { translationBR, translationDE, translationEN } from './config/locales';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  br: {
    translation: translationBR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'br',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
