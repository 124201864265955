import firebase from 'firebase/compat';
import env from '@app/config/env';

import { Permissions } from '../permissions/enums';

import { notificationController } from '@app/controllers/notificationController';

import {
  CompanyModel,
  CompanyTableData,
  GetCompanies,
  CreateCompany,
  GetSingleCompanyData,
  UpdateCompany,
  DeleteCompany,
  GetAllCompanies,
  TestIntegration,
} from './types';

const isMocked = env.REACT_APP_USE_MOCK;

export const getCompanies: GetCompanies = async (params) => {
  const { companyData, queryCommand, pageSize, filterIds } = params;
  let query = firebase
    .firestore()
    .collection('companies')
    .limit(pageSize + 1);

  if (filterIds) {
    query = query.where(firebase.firestore.FieldPath.documentId(), 'in', filterIds);
  }

  if (companyData && queryCommand) {
    if (queryCommand === 'next') {
      query = query.startAt(companyData.snapshotRef).limit(pageSize + 1);
    } else if (queryCommand === 'previous') {
      query = query.endAt(companyData.snapshotRef).limitToLast(pageSize + 1);
    }
  }

  try {
    const companiesSnapshot = await query.get();

    const companiesData = companiesSnapshot.docs.map((doc) => {
      return { ...doc.data(), uid: doc.id, snapshotRef: doc };
    });

    return companiesData as CompanyTableData[];
  } catch (err) {
    notificationController.error({ message: 'Erro ao carregar lista de empresas.' });
    isMocked && console.error(`[ERROR - getCompanies]: `, err);
    return [];
  }
};

export const getAllCompanies: GetAllCompanies = async (filterIds) => {
  try {
    const allCompanies: CompanyModel[] = [];
    let query = firebase.firestore().collection('companies').orderBy(firebase.firestore.FieldPath.documentId());

    if (filterIds) {
      query = query.where(firebase.firestore.FieldPath.documentId(), 'in', filterIds);
    }

    const querySnap = await query.get();

    querySnap.docs.forEach(async (doc) => {
      const data = doc.data();

      allCompanies.push({
        ...data,
        uid: doc.id,
      } as CompanyModel);
    });

    return allCompanies;
  } catch (err) {
    if (isMocked) {
      console.error(`[ERROR - getAllCompanies]: `, err);
    }
    return [];
  }
};

export const createCompany: CreateCompany = async (data) => {
  try {
    const firebaseCreateCompany = firebase.functions().httpsCallable('createCompany');
    const response = await firebaseCreateCompany(data);

    notificationController.success({ message: 'Empresa criada com sucesso.' });
    return response;
  } catch (error) {
    notificationController.error({ message: 'Erro ao criar nova empresa' });
    isMocked && console.error('[ERROR - createCompany]: ', error);
  }
};

export const getSingleCompanyData: GetSingleCompanyData = async (uid) => {
  try {
    const companySnap = await firebase.firestore().collection('companies').doc(uid).get();

    if (!companySnap.exists) {
      notificationController.error({ message: 'Empresa não encontrada na base de dados.' });
      return;
    }

    const companyData = companySnap.data();

    return { ...companyData, uid: companySnap.id } as CompanyModel;
  } catch (error) {
    isMocked && console.error('[ERROR - getSingleRoleData]: ', error);
    notificationController.error({ message: 'Erro ao carregar dados da empresa.' });
  }
};

export const updateCompany: UpdateCompany = async (params) => {
  const { uid, data } = params;

  try {
    await firebase.firestore().collection('companies').doc(uid).update(data);
    notificationController.success({ message: 'Empresa atualizada.' });
  } catch (err) {
    notificationController.error({ message: 'Erro ao atualizar empresa.' });
    isMocked && console.error(err);
  }
};

export const deleteCompany: DeleteCompany = async (params) => {
  const { uid, permissions, isAdmin } = params;

  const canDeleteRole = isAdmin || permissions.includes(Permissions['EDIT_COMPANIES']);

  if (!canDeleteRole) {
    notificationController.error({ message: 'Usuário não tem permissão para deletar usuários' });
    return;
  }

  try {
    await firebase.firestore().collection('companies').doc(uid).delete();
  } catch (error) {
    notificationController.error({ message: 'Erro ao tentar deletar usuário' });
    isMocked && console.error('[ERROR - deleteUser]: ', error);
  }
};

export const testIntegration: TestIntegration = async (params) => {
  try {
    const firebaseTestIntegration = firebase.functions().httpsCallable('testIntegration');
    const response = await firebaseTestIntegration(params);

    notificationController.success({ message: 'Integração validada com sucesso' });
    return response;
  } catch (error) {
    notificationController.error({ message: 'Erro ao validar integração' });
    isMocked && console.error('[ERROR - testIntegration]: ', error);
  }
};
