import React, { useState } from 'react';
import { ShopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

import { useSelectCompany } from '@app/hooks/useSelectCompany';

export const CompanySelect: React.FC = () => {
  const { t } = useTranslation();
  const { mobileOnly } = useResponsive();
  const {
    isLoading,
    companyUid,
    canSeeInput,
    selectData,
    selectedCompany,
    bankAccountsId,
    handleSelectCompany,
    handleSelectBankAccount,
  } = useSelectCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const widthCompanySelect = mobileOnly ? '100%' : '25rem';
  const widthBankSelect = mobileOnly ? '100%' : '20rem';

  return (
    <>
      {canSeeInput ? (
        <>
          {mobileOnly ? (
            <>
              <BaseButton type={'text'} icon={<ShopOutlined onClick={() => setIsModalOpen(!isModalOpen)} />} />
            </>
          ) : (
            <>
              <BaseSelect
                width={widthCompanySelect}
                placeholder={t('companies.finances.selectCompany')}
                disabled={isLoading}
                loading={isLoading}
                options={selectData.map((option) => ({
                  label: `${option.fantasyName} (${option.cnpj})`,
                  value: option.uid,
                }))}
                onChange={(value) => handleSelectCompany(value as string)}
                value={companyUid}
              />

              {selectedCompany?.uid && (
                <BaseSelect
                  listHeight={120}
                  maxTagCount={1}
                  width={widthBankSelect}
                  placeholder={t('companies.finances.selectBank')}
                  disabled={isLoading}
                  loading={isLoading}
                  options={selectedCompany.bankAccounts.map((option) => ({
                    label: option.name,
                    value: option.id,
                  }))}
                  onChange={(value) => handleSelectBankAccount(value as string[])}
                  value={bankAccountsId}
                  mode="multiple"
                />
              )}
            </>
          )}
          <BaseModal
            title={t('companies.finances.selectCompany')}
            open={isModalOpen}
            onCancel={() => setIsModalOpen(!isModalOpen)}
            footer={null}
          >
            <BaseSelect
              style={{ marginBottom: 10 }}
              width={widthCompanySelect}
              size="large"
              placeholder={t('companies.finances.selectCompany')}
              disabled={isLoading}
              loading={isLoading}
              options={selectData.map((option) => ({ label: option.companyName, value: option.uid }))}
              onChange={(value) => handleSelectCompany(value as string)}
              value={companyUid}
            />

            {selectedCompany?.uid && (
              <BaseSelect
                width={widthBankSelect}
                listHeight={120}
                size="large"
                placeholder={t('companies.finances.selectBank')}
                disabled={isLoading}
                loading={isLoading}
                options={selectedCompany.bankAccounts.map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
                onChange={(value) => handleSelectBankAccount(value as string[])}
                value={bankAccountsId}
                mode="multiple"
              />
            )}
          </BaseModal>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};
