import env from '@app/config/env';

import * as integration from './integration';
import * as mock from './mock';
import {
  ValidateUserPermissions,
  ValidateUserRoles,
  GetAccessRoles,
  GetPermissionsData,
  CreateAccessRole,
  GetSingleRoleData,
  UpdateAccessRole,
  DeleteAccessRole,
  GetAllAccessRoles,
} from './types';

const selectedModule = env.REACT_APP_USE_MOCK ? mock : integration;

export const validateUserRoles: ValidateUserRoles = selectedModule.validateUserRoles;
export const validateUserPermissions: ValidateUserPermissions = selectedModule.validateUserPermissions;
export const getAccessRoles: GetAccessRoles = selectedModule.getAccessRoles;
export const getAllAccessRoles: GetAllAccessRoles = selectedModule.getAllAccessRoles;
export const getPermissionsData: GetPermissionsData = selectedModule.getPermissionsData;
export const createAccessRole: CreateAccessRole = selectedModule.createAccessRole;
export const getSingleRoleData: GetSingleRoleData = selectedModule.getSingleRoleData;
export const updateAccessRole: UpdateAccessRole = selectedModule.updateAccessRole;
export const deleteAccessRole: DeleteAccessRole = selectedModule.deleteAccessRole;

export * from './types';
