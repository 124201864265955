import firebase from 'firebase/compat';
import env from '@app/config/env';
import { notificationController } from '@app/controllers/notificationController';
import { SignIn, ResetPassword, GetUpdatedUserData, SignOut } from './types';
import { readAuth } from '@app/services/localStorage';

const isMocked = env.REACT_APP_USE_MOCK;

export const signIn: SignIn = async (params) => {
  try {
    const response = await firebase.auth().signInWithEmailAndPassword(params.email, params.password);

    if (!response.user) {
      throw new Error('[Firebase - signIn]: Usuário não encontrado.');
    }

    const firebaseLoggedUserData = firebase.functions().httpsCallable('getLoggedUserData');

    const loggedUserData = await firebaseLoggedUserData({ uid: response.user.uid }).then((res) => res.data);

    if (!loggedUserData) {
      throw new Error('[Firebase - signIn]: Usuário não encontrado.');
    }

    await firebase
      .auth()
      .setPersistence(
        params.rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION,
      );

    const { permissions, roles, ...user } = loggedUserData;

    const tokenIdFirebase = await response.user.getIdToken(true);
    const auth = {
      token: tokenIdFirebase,
      roles,
      permissions,
    };

    return { user: { ...user, uid: response.user.uid }, auth };
  } catch (err) {
    notificationController.error({ message: 'Falha ao tentar realizar login.' });
    throw new Error(`[Firebase - signIn]: ${err}`);
  }
};

export const signOut: SignOut = async () => {
  try {
    await firebase.auth().signOut();
  } catch (err) {
    notificationController.error({ message: 'Falha ao tentar deslogar.' });
    throw new Error(`[Firebase - signOut]: ${err}`);
  }
};

export const getUpdatedUserData: GetUpdatedUserData = async (userId) => {
  try {
    const firebaseLoggedUserData = firebase.functions().httpsCallable('getLoggedUserData');

    const loggedUserData = await firebaseLoggedUserData({ uid: userId }).then((res) => res.data);

    if (!loggedUserData) {
      throw new Error('[Firebase - signIn]: Usuário não encontrado.');
    }

    const { permissions, roles, ...user } = loggedUserData;

    const currentAuth = readAuth();
    const auth = {
      token: currentAuth?.token as string,
      roles,
      permissions,
    };

    return { user: { ...user, uid: userId }, auth };
  } catch (err) {
    notificationController.error({ message: 'Falha ao tentar obeter os dados atualizados do usuário.' });
    throw new Error(`[Firebase - getUpdatedUserData]: ${err}`);
  }
};

export const resetPassword: ResetPassword = async (email: string) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      notificationController.success({ message: 'Email de recuperação de senha enviado com sucesso.' });
    })
    .catch((err) => {
      isMocked && console.error(`[ERROR][Firebase - recoverPassword]: ${err}`);
      notificationController.error({ message: `Erro na recuperação de senha` });
    });
};
