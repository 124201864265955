import { createAction, createSlice, PrepareAction, Slice } from '@reduxjs/toolkit';
import { persistCompany, readAuth, readCompany } from '@app/services/localStorage';
import { CompanyStored } from '@app/services/companies';

export type CompanyState = CompanyStored;

const initialState: CompanyState = readCompany();

const persistData = (company: CompanyStored, persist: boolean) => {
  if (persist) {
    persistCompany(company, true);
  } else {
    persistCompany(company, false);
  }
};

export const setCompany = createAction<PrepareAction<{ newCompanyId: CompanyState['companyUid']; persist: boolean }>>(
  'company/setCompany',
  (newCompanyId) => {
    const authData = readAuth(true);

    return {
      payload: {
        newCompanyId,
        persist: Boolean(authData),
      },
    };
  },
);

export const setBankAccount = createAction<
  PrepareAction<{ newBankIds: CompanyState['bankAccountsId']; persist: boolean }>
>('company/setBankAccount', (newBankIds) => {
  const authData = readAuth(true);

  return {
    payload: {
      newBankIds,
      persist: Boolean(authData),
    },
  };
});

export const companySlice: Slice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCompany, (state, action) => {
      state.companyUid = action.payload.newCompanyId;
      state.bankAccountsId = [];
      persistData(state, action.payload.persist);
    });
    builder.addCase(setBankAccount, (state, action) => {
      state.bankAccountsId = action.payload.newBankIds;
      persistData(state, action.payload.persist);
    });
  },
});

export default companySlice.reducer;
