import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doUpdatedUserData } from '@app/store/slices/authSlice';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { routes } from './Routes';

export const MAIN_HOME = '/';

export const AppRouter: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(doUpdatedUserData());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {Object.values(routes).map((route) => (
          <Route key={route.path} index={route.path === MAIN_HOME} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
