import React from 'react';
import { ProfileOutlined, DollarOutlined, HomeOutlined } from '@ant-design/icons';
import { Permissions } from '@app/services/permissions/enums';
import routeParams from '@app/config/router/utils/routeParams';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  permissions?: Permissions[];
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'homePage.title',
    key: 'homePage',
    icon: <HomeOutlined />,
    permissions: [],
    url: routeParams.home.path,
  },
  {
    title: 'common.registrations',
    key: 'registrations',
    icon: <ProfileOutlined />,
    permissions: [
      ...routeParams.users.permissions,
      ...routeParams.roles.permissions,
      ...routeParams.companies.permissions,
    ],
    children: [
      {
        title: 'users.title',
        key: 'users',
        url: routeParams.users.path,
        permissions: routeParams.users.permissions,
      },
      {
        title: 'roles.title',
        key: 'roles',
        url: routeParams.roles.path,
        permissions: routeParams.roles.permissions,
      },
      {
        title: 'companies.title',
        key: 'companies',
        url: routeParams.companies.path,
        permissions: routeParams.companies.permissions,
      },
    ],
  },
  {
    title: 'companies.finances.title',
    key: 'finances',
    icon: <DollarOutlined />,
    permissions: [
      ...routeParams.accountsPlansTable.permissions,
      ...routeParams.financesTable.permissions,
      ...routeParams.selectDemonstrativeMonth.permissions,
      ...routeParams.selectYearConsolidated.permissions,
    ],
    children: [
      {
        title: 'accountsPlans.title',
        key: 'accountsPlans',
        url: routeParams.accountsPlansTable.path,
        permissions: routeParams.accountsPlansTable.permissions,
      },
      {
        title: 'companies.finances.weeklyTransactions',
        key: 'weeklyTransactions',
        url: routeParams.financesTable.path,
        permissions: routeParams.financesTable.permissions,
      },
      {
        title: 'companies.finances.demonstrativeResults',
        key: 'demonstrativeResults',
        url: routeParams.selectDemonstrativeMonth.path,
        permissions: routeParams.selectDemonstrativeMonth.permissions,
      },
      {
        title: 'companies.finances.consolidated',
        key: 'consolidated',
        url: routeParams.selectYearConsolidated.path,
        permissions: routeParams.selectYearConsolidated.permissions,
      },
    ],
  },
];
