import { Permissions } from '@app/services/permissions/enums';

const routeParams = {
  home: {
    path: '/',
    permissions: [],
  },
  error404: {
    path: '/404',
    permissions: [],
  },
  serverError: {
    path: '/server-error',
    permissions: [],
  },
  profile: {
    path: '/profile',
    permissions: [Permissions.VIEW_PROFILE],
  },
  personalInfo: {
    path: '/profile/personal-info',
    permissions: [Permissions.VIEW_PROFILE, Permissions.EDIT_PROFILE],
  },
  securitySettings: {
    path: '/profile/security-settings',
    permissions: [Permissions.VIEW_PROFILE],
  },
  users: {
    path: '/users',
    permissions: [Permissions.VIEW_ALL_USERS],
  },
  newUser: {
    path: '/users/new-user',
    permissions: [Permissions.CREATE_USERS],
  },
  userInfo: {
    path: '/users/user/:uid',
    permissions: [Permissions.VIEW_USER_DATA, Permissions.EDIT_USERS],
  },
  roles: {
    path: '/roles',
    permissions: [Permissions.VIEW_ALL_ROLES],
  },
  newRole: {
    path: '/roles/new-role',
    permissions: [Permissions.CREATE_ROLES],
  },
  roleInfo: {
    path: '/roles/role/:uid',
    permissions: [Permissions.VIEW_ROLE_DATA, Permissions.EDIT_ROLES],
  },
  companies: {
    path: '/companies',
    permissions: [Permissions.VIEW_ALL_COMPANIES],
  },
  newCompany: {
    path: '/companies/new-company',
    permissions: [Permissions.CREATE_COMPANIES],
  },
  companyInfo: {
    path: '/companies/company/:uid',
    permissions: [Permissions.VIEW_COMPANY_DATA, Permissions.EDIT_COMPANIES],
  },
  financesTable: {
    path: '/finances',
    permissions: [Permissions.VIEW_OWN_COMPANY_FINANCES, Permissions.VIEW_ALL_COMPANIES_FINANCES],
  },
  financesPeriodInfo: {
    path: '/finances/period/:uid',
    permissions: [Permissions.VIEW_PERIOD_FINANCES],
  },
  selectDemonstrativeMonth: {
    path: '/finances/month-demonstrative',
    permissions: [Permissions.VIEW_MONTH_DEMONSTRATIVE],
  },
  demonstrativeResults: {
    path: '/finances/month-demonstrative/:year/:month',
    permissions: [Permissions.VIEW_MONTH_DEMONSTRATIVE],
  },
  selectYearConsolidated: {
    path: '/finances/consolidated-demonstrative',
    permissions: [Permissions.VIEW_CONSOLIDATED_DEMONSTRATIVE],
  },
  consolidatedDemonstrative: {
    path: '/finances/consolidated-demonstrative/:year',
    permissions: [Permissions.VIEW_CONSOLIDATED_DEMONSTRATIVE],
  },
  newAccountsPlan: {
    path: '/accounts-plans/new-account',
    permissions: [Permissions.CREATE_ACCOUNTS_PLANS],
  },
  accountsPlansTable: {
    path: '/accounts-plans',
    permissions: [Permissions.VIEW_ACCOUNTS_PLANS],
  },
  accountsPlanInfo: {
    path: '/accounts-plans/plan/:uid',
    permissions: [Permissions.VIEW_ACCOUNTS_PLANS, Permissions.EDIT_ACCOUNTS_PLANS],
  },
  monthReport: {
    path: '/month-report/:year/:month',
    permissions: [Permissions.VIEW_MONTH_REPORT],
  },
  consolidatedReport: {
    path: 'finances/consolidated-report/:year',
    permissions: [Permissions.VIEW_MONTH_REPORT],
  },
  login: {
    path: '/auth/login',
    permissions: [],
  },
  forgotPassword: {
    path: '/auth/forgot-password',
    permissions: [],
  },
  logout: {
    path: 'logout',
    permissions: [],
  },
};

export default routeParams;
