import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useIsAdmin } from '@app/hooks/permissions';
import { Permissions } from '@app/services/permissions/enums';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const userPermissions = useAppSelector((state) => state.auth?.permissions) || [];
  const { t } = useTranslation();
  const location = useLocation();
  const isAdmin = useIsAdmin();

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const permittedSidebarItems = sidebarNavigation.filter((item) => {
    if (isAdmin || !item.permissions?.length) {
      return true;
    }

    return userPermissions.some((permission: Permissions) => item.permissions?.includes(permission as Permissions));
  });

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={permittedSidebarItems.map((nav) => {
        const isSubMenu = nav.children?.length;

        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
          icon: nav.icon,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map((childNav) => {
              const hasPermissions = userPermissions.some((permission: Permissions) =>
                childNav.permissions?.includes(permission as Permissions),
              );

              if (isAdmin || hasPermissions) {
                return {
                  key: childNav.key,
                  label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                  title: t(childNav.title),
                };
              }
            }),
        };
      })}
    />
  );
};

export default SiderMenu;
