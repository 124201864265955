import { Permissions } from '@app/services/permissions/enums';

import { validateUserPermissions } from '@app/services/permissions';

import { useAppSelector } from '@app/hooks/reduxHooks';

export const useCanEvery = (permissions: Permissions[]) => {
  const auth = useAppSelector((state) => state.auth);
  const userPermissions = auth?.permissions as Permissions[];

  const { hasEvery } = validateUserPermissions({ userPermissions, permissions });

  return hasEvery;
};
