import env from '@app/config/env';

import * as integration from './integration';
import * as mock from './mock';
import {
  GetCompanies,
  CreateCompany,
  GetSingleCompanyData,
  UpdateCompany,
  DeleteCompany,
  GetAllCompanies,
  TestIntegration,
} from './types';

const selectedModule = env.REACT_APP_USE_MOCK ? mock : integration;

export const getCompanies: GetCompanies = selectedModule.getCompanies;

export const createCompany: CreateCompany = selectedModule.createCompany;

export const getSingleCompanyData: GetSingleCompanyData = selectedModule.getSingleCompanyData;

export const updateCompany: UpdateCompany = selectedModule.updateCompany;

export const deleteCompany: DeleteCompany = selectedModule.deleteCompany;

export const getAllCompanies: GetAllCompanies = selectedModule.getAllCompanies;

export const testIntegration: TestIntegration = selectedModule.testIntegration;

export * from './types';
