import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { useCanSome, useIsAdmin } from './permissions';

import { Permissions } from '@app/services/permissions/enums';
import { setCompany, setBankAccount } from '@app/store/slices/companySlice';
import { CompanyModel, getAllCompanies } from '@app/services/companies';

export const useSelectCompany = () => {
  const dispatch = useAppDispatch();
  const { companyUid, bankAccountsId } = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user);

  const isAdmin = useIsAdmin();
  const canSeeAllCompanies = useCanSome([Permissions.VIEW_ALL_COMPANIES_FINANCES]) || isAdmin;
  const canSeeOnlyFilteredCompanies = useCanSome([Permissions.VIEW_OWN_COMPANY_FINANCES]);

  const [isLoading, setIsLoading] = useState(false);
  const [canSeeInput, setCanSeeInput] = useState(false);
  const [selectData, setSelectData] = useState<CompanyModel[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyModel>();

  const handleSelectCompany = (companyId: string) => {
    dispatch(setCompany(companyId));
    setSelectedCompany(selectData.find((company) => company.uid === companyId));
  };

  const handleSelectBankAccount = (bankAccountsId: string[]) => {
    dispatch(setBankAccount(bankAccountsId));
  };

  const fetchCompaniesForSelect = useCallback(async (filterIds?: string[]) => {
    setIsLoading(true);
    const companies = await getAllCompanies(filterIds);

    if (companies?.length) {
      setSelectData(companies);
      setSelectedCompany(companies.find((company) => company.uid === companyUid));
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canSeeAllCompanies) {
      setCanSeeInput(true);
      fetchCompaniesForSelect();
    } else if (canSeeOnlyFilteredCompanies) {
      setCanSeeInput(true);
      fetchCompaniesForSelect(user.companies);
    }
  }, [canSeeOnlyFilteredCompanies, canSeeAllCompanies, fetchCompaniesForSelect, user]);

  return {
    isLoading,
    companyUid,
    canSeeInput,
    selectData,
    selectedCompany,
    bankAccountsId,
    handleSelectCompany,
    handleSelectBankAccount,
  };
};
