import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { Permissions } from '@app/services/permissions/enums';
import { WithChildrenProps } from '@app/types/generalTypes';

import { useAppSelector } from '@app/hooks/reduxHooks';
import { useCanEvery, useCanSome, useIsAdmin } from '@app/hooks/permissions';

interface IControlWrap extends WithChildrenProps {
  permissions: Permissions[];
  allRequiredPermissions: Permissions[];
  redirect?: ReactNode;
}

const ControlWrap: React.FC<IControlWrap> = ({ children, permissions, allRequiredPermissions, redirect }) => {
  const auth = useAppSelector((state) => state.auth);
  const isAdmin = useIsAdmin();

  const hasEveryPermissions = useCanEvery(allRequiredPermissions);
  const hasSomePermissions = useCanSome(permissions);

  const hasPermission = hasEveryPermissions && hasSomePermissions;

  return auth?.token ? <>{hasPermission || isAdmin ? children : redirect}</> : <Navigate to="/auth/login" replace />;
};

export default ControlWrap;
