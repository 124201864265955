import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import { UserModel } from '@app/services/firebase/users';
import * as S from './styles';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  return profileData ? (
    <S.Wrapper>
      <UserOutlined style={{ fontSize: '5rem', justifyContent: 'center', marginBottom: '8px' }} />
      <S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>
    </S.Wrapper>
  ) : null;
};
