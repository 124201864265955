import env from '@app/config/env';
import { SignIn, ResetPassword, GetUpdatedUserData, SignOut } from './types';
import { notificationController } from '@app/controllers/notificationController';
const avatarImg = env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

import { sleep } from '@app/utils';

export const signIn: SignIn = async () => {
  await sleep(3000);

  return {
    user: {
      uid: '1',
      firstName: 'Chris',
      lastName: 'Johnson',
      imgUrl: avatarImg,
      userName: '@john1989',
      email: 'chris.johnson@altence.com',
      phone: '18143519459',
      birthdate: '01/26/2022',
      lang: 'br',
      city: 'London',
      address: '14 London Road',
      postalcode: '5211',
      website: 'altence.com',
      socials: {
        twitter: '@altence_team',
        facebook: 'https://facebook.com/groups/1076577369582221',
        linkedin: 'https://linkedin.com/company/altence',
      },
      companies: [],
      roles: [],
    },
    auth: {
      token: 'token',
      permissions: [],
      roles: ['admin'],
    },
  };
};

export const getUpdatedUserData: GetUpdatedUserData = async () => {
  await sleep(3000);

  return {
    user: {
      uid: '1',
      firstName: 'Chris',
      lastName: 'Johnson',
      imgUrl: avatarImg,
      userName: '@john1989',
      email: 'chris.johnson@altence.com',
      phone: '18143519459',
      birthdate: '01/26/2022',
      lang: 'br',
      city: 'London',
      address: '14 London Road',
      postalcode: '5211',
      website: 'altence.com',
      socials: {
        twitter: '@altence_team',
        facebook: 'https://facebook.com/groups/1076577369582221',
        linkedin: 'https://linkedin.com/company/altence',
      },
      companies: [],
      roles: [],
    },
    auth: {
      token: 'token',
      permissions: [],
      roles: ['admin'],
    },
  };
};

export const resetPassword: ResetPassword = async () => {
  return notificationController.success({ message: 'Email de recuperação de senha enviado com sucesso.' });
};

export const signOut: SignOut = async () => {
  await sleep(3000);
};
