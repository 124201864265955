import env from '@app/config/env';

import * as integration from './integration';
import * as mock from './mock';
import { PersistAuth, PersistCompany, PersistUser, ReadAuth, ReadCompany, ReadUser } from './types';

const selectedModule = env.REACT_APP_USE_MOCK ? mock : integration;

export const persistAuth: PersistAuth = selectedModule.persistAuth;

export const readAuth: ReadAuth = selectedModule.readAuth;

export const persistUser: PersistUser = selectedModule.persistUser;

export const readUser: ReadUser = selectedModule.readUser;

export const readCompany: ReadCompany = selectedModule.readCompany;

export const persistCompany: PersistCompany = selectedModule.persistCompany;

export const deleteToken = selectedModule.deleteAuth;
export const deleteUser = selectedModule.deleteUser;
export const deleteCompany = selectedModule.deleteCompany;

export * from './types';
