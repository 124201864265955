import React from 'react';
import { SecurityScanOutlined, UserOutlined } from '@ant-design/icons';

import routeParams from '@app/config/router/utils/routeParams';

interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success';
  href: string;
}

export const profileNavData: ProfileNavItem[] = [
  {
    id: 1,
    name: 'profile.nav.personalInfo.title',
    icon: <UserOutlined />,
    color: 'primary',
    href: routeParams.personalInfo.path,
  },
  {
    id: 2,
    name: 'profile.nav.securitySettings.title',
    icon: <SecurityScanOutlined />,
    color: 'success',
    href: routeParams.securitySettings.path,
  },
];
