enum Permissions {
  CREATE_ACCOUNTS_PLANS = 'create_accounts_plans',
  CREATE_COMPANIES = 'create_companies',
  CREATE_ROLES = 'create_roles',
  CREATE_USERS = 'create_users',
  DELETE_ACCOUNTS_PLANS = 'delete_accounts_plans',
  DELETE_COMPANIES = 'delete_companies',
  DELETE_ROLES = 'delete_roles',
  DELETE_USERS = 'deleter_users',
  EDIT_COMPANIES = 'edit_companies',
  EDIT_FINANCES = 'edit_finances',
  EDIT_PROFILE = 'edit_profile',
  EDIT_ROLES = 'edit_roles',
  EDIT_USERS = 'edit_users',
  EDIT_ACCOUNTS_PLANS = 'edit_accounts_plans',
  UPDATE_PERIOD_FINANCES = 'update_period_finances',
  VIEW_ALL_COMPANIES = 'view_companies',
  VIEW_ALL_COMPANIES_FINANCES = 'view_all_finances',
  VIEW_ALL_ROLES = 'view_roles',
  VIEW_ALL_USERS = 'view_users',
  VIEW_COMPANY_DATA = 'view_company_data',
  VIEW_OWN_COMPANY_FINANCES = 'view_own_company_finances',
  VIEW_PROFILE = 'view_profile',
  VIEW_ROLE_DATA = 'view_role_data',
  VIEW_USER_DATA = 'view_user_data',
  VIEW_ACCOUNTS_PLANS = 'view_accounts_plans',
  VIEW_ACCOUNTS_PLAN_DATA = 'view_accounts_plan_data',
  VIEW_PERIOD_FINANCES = 'view_period_finances',
  VIEW_MONTH_DEMONSTRATIVE = 'view_month_demonstrative',
  VIEW_CONSOLIDATED_DEMONSTRATIVE = 'view_consolidated_demonstrative',
  VIEW_INDICATORS = 'view_indicators',
  VIEW_MONTH_REPORT = 'view_month_report',
  GENERATE_REPORT_PDF = 'generate_report_pdf',
}

export default Permissions;
