import { sleep } from '@app/utils';
import { Permissions } from './enums';
import {
  ValidateUserRoles,
  ValidateUserPermissions,
  GetAccessRoles,
  GetPermissionsData,
  CreateAccessRole,
  GetSingleRoleData,
  UpdateAccessRole,
  DeleteAccessRole,
  GetAllAccessRoles,
} from './types';

const mockPermissionData = [
  {
    key: Permissions.EDIT_PROFILE,
    name: 'editar perfil',
    feature: 'profile',
    featureName: 'Perfil do usuário',
    ref: 'reference',
  },
];

const mockAccessRoles = [
  {
    uid: '1',
    key: 'admin',
    roleName: 'Administrador',
    permissions: mockPermissionData,
    description: '',
  },
];

const mockSingleAccessRole = {
  uid: '1',
  key: 'admin',
  roleName: 'Administrador',
  permissions: mockPermissionData,
  description: '',
};

export const getAccessRoles: GetAccessRoles = async () => {
  await sleep(2000);
  return mockAccessRoles;
};

export const getAllAccessRoles: GetAllAccessRoles = async () => {
  await sleep(2000);
  return mockAccessRoles;
};

export const getPermissionsData: GetPermissionsData = async () => {
  await sleep(2000);
  return mockPermissionData;
};

export const getSingleRoleData: GetSingleRoleData = async () => {
  await sleep(2000);
  return mockSingleAccessRole;
};

export const createAccessRole: CreateAccessRole = async () => {
  await sleep(2000);
  return;
};

export const updateAccessRole: UpdateAccessRole = async () => {
  await sleep(1000);
};

export const deleteAccessRole: DeleteAccessRole = async () => {
  await sleep(2000);

  return;
};

export const validateUserRoles: ValidateUserRoles = () => {
  return { hasEvery: true, hasSome: true };
};

export const validateUserPermissions: ValidateUserPermissions = () => {
  return { hasEvery: true, hasSome: true };
};
