import { PersistAuth, PersistCompany, PersistUser, ReadAuth, ReadCompany, ReadUser } from './types';

export const persistAuth: PersistAuth = (auth, persist): void => {
  if (persist) {
    localStorage.setItem('auth', JSON.stringify(auth));
  } else {
    sessionStorage.setItem('auth', JSON.stringify(auth));
  }
};

export const readAuth: ReadAuth = (fromLocalStorageOnly) => {
  const authStr = !fromLocalStorageOnly
    ? localStorage.getItem('auth') || sessionStorage.getItem('auth')
    : localStorage.getItem('auth');

  return authStr ? JSON.parse(authStr) : null;
};

export const persistUser: PersistUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser: ReadUser = () => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : null;
};

export const readCompany: ReadCompany = () => {
  const companyStr = localStorage.getItem('company') || sessionStorage.getItem('company');

  return companyStr ? JSON.parse(companyStr) : { bankAccountsId: [], companyUid: null };
};

export const persistCompany: PersistCompany = (company, persist) => {
  if (persist) {
    localStorage.setItem('company', JSON.stringify(company));
  } else {
    sessionStorage.setItem('company', JSON.stringify(company));
  }
};

export const deleteAuth = (): void => localStorage.removeItem('auth');
export const deleteUser = (): void => localStorage.removeItem('user');
export const deleteCompany = (): void => localStorage.removeItem('company');
